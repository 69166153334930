@font-face {
  font-family: 'Rubik';
  src: url('../assets/fonts/Rubik-Light.woff') format('woff');
  font-weight: 300;
  font-style: normal;
}
@font-face {
  font-family: 'Rubik';
  src: url('../assets/fonts/Rubik-Regular.woff') format('woff');
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: 'Rubik';
  src: url('../assets/fonts/Rubik-Medium.woff') format('woff');
  font-weight: 500;
  font-style: normal;
}
@font-face {
  font-family: 'Rubik';
  src: url('../assets/fonts/Rubik-Bold.woff') format('woff');
  font-weight: 700;
  font-style: normal;
}
@font-face {
  font-family: 'Rubik';
  src: url('../assets/fonts/Rubik-Black.woff') format('woff');
  font-weight: 900;
  font-style: normal;
}

body {
  font-family: 'Rubik';
}

h1,
h2,
h3,
h4 {
  span {
    font-size: 16px;
    font-weight: 400;
    font-style: italic;
  }
}

h1 {
  font-size: 1.5rem; // 26px
  font-weight: $normal;
}
h2 {
  font-size: 1.25rem; // 20px
  font-weight: $normal;
}
h3 {
  font-size: 1.125rem; // 18px
  font-weight: $normal;
}
h4 {
  font-size: 1rem; // 16px
  font-weight: $normal;
}
p {
  font-size: 1rem; // 16px
  font-weight: $light;
}
.font-mediumEmphasis {
  color: rgba(0, 0, 0, 0.6);
}
